import type { FunctionComponent, SVGProps } from 'react';
import { RoutePath } from '@shared/constants/routing.constants';
import {
  ChargerIcon,
  VehicleIcon,
  InfoIcon,
  MegaphoneIcon,
  OrganisationsIcon,
  SettingsIcon,
  SiteIcon,
  UsersIcon,
  ChargingSessionsIcon,
} from '@assets/icons';
import type { DashboardLayoutTranslationKey } from '@i18n/locales/dashboard-layout';
import { config } from '@utils/config';

export type NavigationDrawerItemId =
  | 'users'
  | 'organisations'
  | 'chargers'
  | 'sites'
  | 'vehicles'
  | 'charging-sessions'
  | 'settings'
  | 'guide'
  | 'release-notes';

export interface NavigationDrawerItemProps {
  id: NavigationDrawerItemId;
  name: DashboardLayoutTranslationKey;
  path: RoutePath | string;
  pathActive?: string; // mark item as active if matching that path
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  isExternalRedirect?: boolean;
}

interface NavigationDrawerSection {
  name: string;
  items: NavigationDrawerItemProps[];
}

export const NavigationDrawerTopSections: NavigationDrawerSection[] = [
  {
    name: 'resources',
    items: [
      {
        id: 'vehicles',
        name: 'dashboard-layout.navigation-drawer-vehicles',
        path: RoutePath.DashboardVehicles,
        pathActive: `${RoutePath.DashboardVehicles}/*`,
        icon: VehicleIcon,
      },
      {
        id: 'chargers',
        name: 'dashboard-layout.navigation-drawer-chargers',
        path: RoutePath.DashboardChargers,
        pathActive: `${RoutePath.DashboardChargers}/*`,
        icon: ChargerIcon,
      },
      {
        id: 'charging-sessions',
        name: 'dashboard-layout.navigation-drawer-charging-sessions',
        path: RoutePath.DashboardChargingSessions,
        pathActive: `${RoutePath.DashboardChargingSessions}/*`,
        icon: ChargingSessionsIcon,
      },
      {
        id: 'sites',
        name: 'dashboard-layout.navigation-drawer-sites',
        path: RoutePath.DashboardSites,
        pathActive: `${RoutePath.DashboardSites}/*`,
        icon: SiteIcon,
      },
      {
        id: 'users',
        name: 'dashboard-layout.navigation-drawer-users',
        path: RoutePath.DashboardUsers,
        pathActive: `${RoutePath.DashboardUsers}/*`,
        icon: UsersIcon,
      },
      {
        id: 'organisations',
        name: 'dashboard-layout.navigation-drawer-organisations',
        path: RoutePath.DashboardOrganisations,
        pathActive: `${RoutePath.DashboardOrganisations}/*`,
        icon: OrganisationsIcon,
      },
      {
        id: 'settings',
        name: 'dashboard-layout.navigation-drawer-settings',
        path: `${RoutePath.DashboardSettingsBase}/${RoutePath.DashboardSettingsGeneral}`,
        pathActive: RoutePath.DashboardSettingsAllRoutes,
        icon: SettingsIcon,
      },
    ],
  },
];

export const NavigationDrawerBottomSections: NavigationDrawerSection[] = [
  {
    name: 'docs',
    items: [
      {
        id: 'release-notes',
        name: 'dashboard-layout.navigation-drawer-release-notes',
        path: RoutePath.DashboardReleaseNotes,
        pathActive: RoutePath.DashboardReleaseNotes,
        icon: MegaphoneIcon,
      },
      {
        id: 'guide',
        name: 'dashboard-layout.navigation-drawer-guide',
        path: config.pandoGuideUrl,
        icon: InfoIcon,
        isExternalRedirect: true,
      },
    ],
  },
];
