import { useFindB2bUserInfo } from '@services/queries/user/find-b2b-user-info.query';
import { OrganisationType } from '@services/types/organisation/organisation.api-response.types';

export enum UserPermission {
  // Read
  VIEW_CHARGERS = 'VIEW_CHARGERS',
  VIEW_SITES = 'VIEW_SITES',
  VIEW_VEHICLES = 'VIEW_VEHICLES',
  VIEW_USERS = 'VIEW_USERS',
  VIEW_ORGANISATIONS = 'VIEW_ORGANISATIONS',
  VIEW_SETTINGS = 'VIEW_SETTINGS',
  VIEW_RELEASE_NOTES = 'VIEW_RELEASE_NOTES',
  VIEW_GUIDE = 'VIEW_GUIDE',

  // Write
  // ADD_CHARGER = 'ADD_CHARGER', // TODO refactor, handle current resources visibility/accessibility here (e.g. manage charger buttons)
}

type UserPermissions = Record<UserPermission, boolean>;

export const useUserPermissions = (): UserPermissions | null => {
  const { data: b2bUserInfo } = useFindB2bUserInfo();

  if (!b2bUserInfo) {
    return null;
  }

  const { isCustomerService } = b2bUserInfo;

  const isInstaller = b2bUserInfo?.organisationTypes.includes(OrganisationType.Installer);
  const isChargePointOperator = b2bUserInfo?.organisationTypes.includes(OrganisationType.ChargePointOperator);
  const isDistributor = b2bUserInfo?.organisationTypes.includes(OrganisationType.Distributor);
  // isUtility - currently not used
  // const isUtility = b2bUserInfo?.organisationTypes.includes(OrganisationType.Utility);
  const isFleetOperator = b2bUserInfo?.organisationTypes.includes(OrganisationType.FleetOperator);
  const isManufacturer = b2bUserInfo?.organisationTypes.includes(OrganisationType.Manufacturer);

  return {
    // Always available
    VIEW_USERS: true,
    VIEW_ORGANISATIONS: true,
    VIEW_SETTINGS: true,
    VIEW_RELEASE_NOTES: true,
    VIEW_GUIDE: true,

    // Read
    VIEW_CHARGERS: isManufacturer || isDistributor || isInstaller || isChargePointOperator || isCustomerService,
    VIEW_SITES: isChargePointOperator || isCustomerService,
    VIEW_VEHICLES: isFleetOperator || isCustomerService,
  };
};
